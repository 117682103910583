import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'events',
  formId: 'DK%20Event%20Planning',
  title: 'Live Events',
  subTitle: 'We’re counting down the days',
  icon: '/icons/events.svg',
  image: '/assets/images/sunrise/events_supermercat.png',
  theme: 'sunrise-events',
  sections: [
    {
      sectionId: 'hero',
      label: 'Live Events',
      title: 'We’re counting down the days',
      description:
        'A big part of an amazing work environment is hanging out with your beloved colleagues. Whether it’s a Friday bar, a summer party, an anniversary or a Christmas lunch: don’t worry, we got you!',
      image: '/assets/images/sunrise/events_supermercat.png',
      icon: '/icons/events.svg',
      buttonText: 'Request a proposal',
    },
    {
      sectionId: 'options',
      title: 'We are fans of people getting together',
      description:
        'We’ve done it all... from the monthly Friday bar to the big annual Christmas party. Save time and let us help you with all the nitty gritty work so you can join the party and not just facilitate it.',
      optionsItems: [
        {
          icon: '/assets/images/services/events/service_1.svg',
          title: 'Friday bar',
          description: 'You design it. Small to BIG. From beers to cocktails, food to DJs. Everything is possible.',
        },
        {
          icon: '/assets/images/services/events/service_2.svg',
          title: 'Anniversary',
          description: 'Work anniversary, birthdays, baby showers! You name it, we’ve done it. It’s appreciation time!',
        },
        {
          icon: '/assets/images/services/events/service_3.svg',
          title: 'Seasonal party',
          description:
            'Summer party, Christmas party, Halloween party? All those special things you need for a seasonal party!',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'The party is just a few steps away',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/events/journey_1.svg',
          title: 'Send us your request',
          description: 'Tell us what would make the event magical.',
        },
        {
          icon: '/assets/images/services/events/journey_2.svg',
          title: 'Get tailored feedback',
          description: 'Together we’ll create the perfect plan for your upcoming event.',
        },
        {
          icon: '/assets/images/services/events/journey_3.svg',
          title: 'Receive a proposal!',
          description: 'We’ll send a detailed proposal with items and prices.',
        },
        {
          icon: '/assets/images/services/events/journey_4.svg',
          title: 'Have a blast!',
          description: 'Party on! Lean back and enjoy the celebration.',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Why companies choose Good Monday to help with their events',
      description: '',
      image: '/assets/images/services/events/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-clock-outline',
          title: 'Save a lot of time',
          description:
            'We’ll take care of all the nasty nitty gritty details - you’ll relax and tell us what you want.',
        },
        {
          icon: 'eva eva-bulb-outline',
          title: 'Everything you can imagine',
          description: 'We have tried it all and have a vast network of ultra cool suppliers.',
        },
        {
          icon: 'eva eva-options-2-outline',
          title: 'Tailored to your needs',
          description:
            'One size <strong>doesn’t</strong> fit all. We make sure you get exactly what you want, tailored to your needs.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Did you know that with Good Monday you can get a monthly Friday bar subscription?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Request a proposal',
    },
  ],
}
